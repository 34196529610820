import React, { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import createTheme from 'themes/createTheme';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';

const Envelope: FC = () => {
  const theme = createTheme('light');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
};

export default observer(Envelope);
