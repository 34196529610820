import IAbstractAuthenticator from 'services/AbstractAuthenticator';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import { PATH_LOGIN } from 'constants/pages';

type JwtPayloadKey = keyof JwtPayload;

class PrimaryAuthenticator implements IAbstractAuthenticator {
  static ACCESS_TOKEN_KEY = 'smat'; // sign manager access token
  static REFRESH_TOKEN_KEY = 'smrt'; // sign manager refresh token
  static REMEMBER_ME_KEY = 'smrm';
  static DELTA_IN_MINUTES = 1;

  public setCredentials(accessToken: string, refreshToken: string) {
    localStorage.setItem(PrimaryAuthenticator.ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(PrimaryAuthenticator.REFRESH_TOKEN_KEY, refreshToken);
    return this;
  }

  public setRememberMe(flag: boolean) {
    localStorage.setItem(PrimaryAuthenticator.REMEMBER_ME_KEY, (+flag).toString());
    return this;
  }

  public getAccessToken = () => localStorage.getItem(PrimaryAuthenticator.ACCESS_TOKEN_KEY);
  public getRefreshToken = () => localStorage.getItem(PrimaryAuthenticator.REFRESH_TOKEN_KEY);
  public isRememberMe = () => !!localStorage.getItem(PrimaryAuthenticator.REMEMBER_ME_KEY);

  public getCredentials = () =>
    Promise.resolve({
      accessToken: this.getAccessToken(),
      refreshToken: this.getRefreshToken(),
    });

  public revokeCredentials() {
    localStorage.removeItem(PrimaryAuthenticator.ACCESS_TOKEN_KEY);
    localStorage.removeItem(PrimaryAuthenticator.REFRESH_TOKEN_KEY);
    localStorage.removeItem(PrimaryAuthenticator.REMEMBER_ME_KEY);
    return this;
  }

  public goToLogin = () => {
    window.location.href = PATH_LOGIN;
  };

  protected checkTokenAvailability(token: string | null) {
    if (token === null) return false;

    const exp = this.jwtDecode<number>(token, 'exp', 1);

    if (exp !== null) {
      const now = moment().add(PrimaryAuthenticator.DELTA_IN_MINUTES, 'minutes');

      return now.utc().unix() <= exp;
    }

    return false;
  }

  public checkAccessTokenAvailability() {
    const token = this.getAccessToken();
    return this.checkTokenAvailability(token);
  }

  public checkRefreshTokenAvailability() {
    const token = this.getRefreshToken();
    return this.checkTokenAvailability(token);
  }

  // protected getCurrentJti = () => {
  //   const accessToken = this.getAccessToken() ?? '';
  //   return <string>this.jwtDecode<string>(accessToken, 'jti', 'secret');
  // };

  protected jwtDecode = <T>(token: string, key: JwtPayloadKey, def: T) => {
    let tokenPayload;
    try {
      tokenPayload = jwtDecode<JwtPayload>(token);
    } catch (e) {
      return def;
    }

    return tokenPayload[key] ?? def;
  };
}

export default PrimaryAuthenticator;
export type TPrimaryAuthenticator = PrimaryAuthenticator;
