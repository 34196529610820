import AppStore, { TAppStore } from 'stores/AppStore';
import { createContext } from 'react';
import ProgramModuleStore, { TProgramModuleStore } from 'stores/ProgramModuleStore';
import PresetModuleStore, { TPresetModuleStore } from 'stores/PresetModuleStore';
import AboutModuleStore, { TAboutModuleStore } from 'stores/AboutModuleStore';
import ConfigStore, { TConfigStore } from 'stores/ConfigStore';
import MessageDialogStore, { TMessageDialogStore } from 'stores/MessageDialogStore';

export default class RootStore {
  appStore: TAppStore;
  configStore: TConfigStore;
  messageDialogStore: TMessageDialogStore;
  programModuleStore: TProgramModuleStore;
  presetModuleStore: TPresetModuleStore;
  aboutModuleStore: TAboutModuleStore;

  constructor() {
    this.appStore = new AppStore(this);
    this.configStore = new ConfigStore(this);
    this.messageDialogStore = new MessageDialogStore(this);
    this.programModuleStore = new ProgramModuleStore(this);
    this.presetModuleStore = new PresetModuleStore(this);
    this.aboutModuleStore = new AboutModuleStore();
  }
}

export type TRootStore = RootStore;
export const RootStoreContext = createContext<TRootStore | null>(null);
