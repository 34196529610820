import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import RootStore, { RootStoreContext } from 'stores/RootStore';
import PrimaryAuthenticator from 'services/PrimaryAuthenticator';
import PrimaryClient from 'services/PrimaryClient';
import { SnackbarProvider } from 'notistack';
import { LinearProgress } from '@mui/material';
import AppRouter from 'router/AppRouter';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const store = new RootStore();
const baseURL = process.env.REACT_APP_API_HOST ?? '';
const authenticator = new PrimaryAuthenticator();
PrimaryClient.createClientInstance(baseURL, authenticator);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <RootStoreContext.Provider value={store}>
    <Suspense fallback={<LinearProgress />}>
      <SnackbarProvider maxSnack={3}>
        <AppRouter />
      </SnackbarProvider>
    </Suspense>
  </RootStoreContext.Provider>
);

serviceWorkerRegistration.register();
