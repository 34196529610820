import { action, computed, makeObservable, observable } from 'mobx';
import update from 'immutability-helper';
import { EMode } from 'types/app';
import _sortBy from 'lodash/sortBy';
import { TRootStore } from 'stores/RootStore';
import { ECustomOrPreset, TMessage } from 'types/message';

class ProgramModuleStore {
  @observable root: TRootStore;
  @observable isInit = false;
  @observable mode: EMode = EMode.VIEW;
  @observable orderedMessages: TMessage[] = [];

  constructor(rootSore: TRootStore) {
    this.root = rootSore;
    makeObservable(this);
  }

  @action
  public initiate = () => {
    this.isInit = true;
  };

  @action
  public changeMode = () => {
    this.mode = this.mode === EMode.VIEW ? EMode.EDIT : EMode.VIEW;
    if (this.isEditMode) this.loadOrderedMessages();
  };

  @action
  public loadOrderedMessages = () => {
    this.orderedMessages = update(_sortBy(this.data, 'stackRank'), {});
  };

  @action
  public setOrderedMessage = (dragIndex: number, hoverIndex: number) => {
    this.orderedMessages = update(this.orderedMessages, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, this.orderedMessages[dragIndex]],
      ],
    });
  };

  @computed
  get isEditMode() {
    return this.mode === EMode.EDIT;
  }

  @computed
  get list() {
    if (this.isEditMode) return this.orderedMessages;
    return _sortBy(this.data, 'stackRank');
  }

  @computed
  get data() {
    return this.root.configStore.messages.filter(i => i.customOrPreset === ECustomOrPreset.CUSTOM);
  }
}

export default ProgramModuleStore;
export type TProgramModuleStore = ProgramModuleStore;
