import { makeObservable, observable, action, computed } from 'mobx';
import PrimaryClient from 'services/PrimaryClient';
import { doRefreshToken, signIn, signOut } from 'services/api/User';
import { ERole, IUser } from 'types/user';
import { TRootStore } from 'stores/RootStore';
import { EElement } from 'types/app';
import { APP_NAME } from 'constants/app';

const DEFAULT_USER = {
  role: ERole.GUEST,
};

const CLIENT_USER = {
  role: ERole.CLIENT,
};

class AppStore {
  @observable root: TRootStore;
  @observable user: IUser = DEFAULT_USER;
  @observable isInit = false;
  @observable isLock = false;
  @observable element: EElement = EElement.HOME;

  constructor(rootSore: TRootStore) {
    this.root = rootSore;
    makeObservable(this);
  }

  @action
  public lock = () => {
    this.isLock = true;
  };

  @action
  public unlock = () => {
    this.isLock = false;
  };

  @action
  public initiate = () => {
    const client = PrimaryClient.getClient();
    const authenticator = client().getAuthenticator();

    if (this.isInit) return;
    if (authenticator.checkAccessTokenAvailability()) {
      this.setUserData(CLIENT_USER);
      this.isInit = true;
    } else if (authenticator.checkRefreshTokenAvailability() && authenticator.isRememberMe()) {
      doRefreshToken(authenticator.getRefreshToken() as string)
        .then(
          action('doRefreshToken', response => {
            this.setUserData(CLIENT_USER);
            this.isInit = true;
            authenticator.setCredentials(response.result.access, response.result.refresh);
          })
        )
        .catch(
          action('doSignInFailure', () => {
            authenticator.revokeCredentials();
            this.isInit = true;
          })
        );
    } else {
      authenticator.revokeCredentials();
      this.isInit = true;
    }
  };

  @action
  public setUserData = (user: IUser) => {
    this.user = user;
  };

  @action
  public resetUserData = () => {
    this.setUserData(DEFAULT_USER);
  };

  @action
  public signIn = (params: { username: string; password: string; remember: boolean }, success: () => void, failure: (response: any) => void) => {
    const { username, password, remember } = params;
    const client = PrimaryClient.getClient();
    const authenticator = client().getAuthenticator();
    signIn(username, password)
      .then(
        action('doSignInSuccess', response => {
          this.setUserData(CLIENT_USER);
          authenticator.setCredentials(response.result.access, response.result.refresh);
          authenticator.setRememberMe(remember);
          success();
        })
      )
      .catch(
        action('doSignInFailure', response => {
          failure(response);
        })
      );
  };

  @action
  public signOut = async (success: () => void) => {
    const client = PrimaryClient.getClient();
    const authenticator = client().getAuthenticator();
    await signOut(authenticator.getRefreshToken() as string);
    authenticator.revokeCredentials();
    this.setUserData(DEFAULT_USER);
    success();
  };

  protected userIs(role: ERole) {
    return this.user.role === role;
  }

  @computed
  get userIsGuest() {
    return this.userIs(ERole.GUEST);
  }

  @computed
  get userIsClient() {
    return this.userIs(ERole.CLIENT);
  }

  @action
  public setElement = (element: EElement, title: string) => {
    this.element = element;
    document.title = `${title} — ${APP_NAME}`;
  };

  @computed
  get elementForMenu() {
    return [EElement.ABOUT, EElement.PROGRAM, EElement.PRESET].includes(this.element) ? this.element : EElement.PROGRAM;
  }
}

export default AppStore;
export type TAppStore = AppStore;
