import { action, computed, makeObservable, observable } from 'mobx';

type TParams = {
  [key: string]: any;
};

export enum EDialog {
  MESSAGE_TIME = 'time',
  MESSAGE_DATE = 'date',
  MESSAGE_PRESET = 'preset',
  MESSAGE_PAUSE = 'pause',
}

class DialogStore {
  @observable params: TParams = {};
  @observable stack: EDialog[] = [];

  constructor() {
    makeObservable(this);
    const p = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    if (p.has('dialog')) {
      this.setParams(Object.fromEntries(p));
    }
  }

  @action
  public open = (dialog: EDialog, params?: TParams) => {
    if (!this.stack.includes(dialog)) this.stack.push(dialog);

    const p = new URLSearchParams(params);
    p.set('dialog', dialog);
    this.setParams(Object.fromEntries(p));
    window.location.hash = p.toString();
  };

  @action
  public setParams = (params: TParams) => {
    if (params.dialog) {
      this.params = params;
    } else {
      this.close();
    }
  };

  @action
  public close = () => {
    this.params = {};
    window.location.hash = '';
  };

  @computed
  get dialog() {
    return this.params?.dialog || null;
  }
}

export default new DialogStore();
