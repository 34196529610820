import { makeObservable, observable, action, computed } from 'mobx';
import { fetchConfig } from 'services/api/Application';
import { TConfigData } from 'types/config';
import { TRootStore } from 'stores/RootStore';
import { TMessage, TMessageRequest } from 'types/message';
import { doDeleteMessage, doCreateMessage, fetchMessages, doUpdateMessage, doReorderMessage } from 'services/api/Messages';
import { Nullable } from 'types/app';

class ConfigStore {
  @observable root: TRootStore;
  @observable isLoaded = false;
  @observable messages: TMessage[] = [];
  @observable data: TConfigData = {
    fonts: [],
    brightness: [0, 10],
    flashRate: [1, 5],
    scrollPace: [1, 20],
  };

  constructor(rootSore: TRootStore) {
    this.root = rootSore;
    makeObservable(this);
  }

  @action
  public fetchConfig = () => {
    if (this.root.appStore.userIsGuest) return;
    if (this.isLoaded) return;

    Promise.all([fetchConfig(), fetchMessages()]).then(
      action('fetchConfigResult', values => {
        const [configResponse, messagesResponse] = values;
        const config = configResponse.result[0];
        const messages = messagesResponse.result;
        this.data = {
          fonts: config.Fonts.map(i => ({ value: i[0], label: i[1] })),
          brightness: [config.brightness_min, config.brightness_max],
          flashRate: [1, config.flash_rate_max],
          scrollPace: [1, config.scroll_pace_max],
        };
        this.messages = messages.map(i => ({
          id: i.id,
          stackRank: i.stack_rank,
          customOrPreset: i.custom_or_preset,
          type: i.type,
          valueText: i.value_text,
          scrollPace: i.scroll_pace,
          flashRate: i.flash_rate,
          font: i.font,
        }));
        this.isLoaded = true;
      })
    );
  };

  @action
  public reFetchMessages = (success?: () => void) => {
    fetchMessages().then(
      action('reFetchMessages', response => {
        const { result } = response;
        this.messages = result.map(i => ({
          id: i.id,
          stackRank: i.stack_rank,
          customOrPreset: i.custom_or_preset,
          type: i.type,
          valueText: i.value_text,
          scrollPace: i.scroll_pace,
          flashRate: i.flash_rate,
          font: i.font,
        }));
        if (success) success();
      })
    );
  };

  @action
  public deleteMessage = (id: string, success: () => void, failure: () => void) => {
    doDeleteMessage(id)
      .then(
        action('doDeleteMessageSuccess', () => {
          this.reFetchMessages(success);
        })
      )
      .catch(
        action('doDeleteMessageFailure', () => {
          failure();
        })
      );
  };

  @action
  public createMessage = (data: TMessageRequest, success: () => void, failure: () => void) => {
    doCreateMessage(data)
      .then(
        action('doCreateMessageSuccess', () => {
          this.reFetchMessages(success);
        })
      )
      .catch(
        action('doCreateMessageFailure', () => {
          failure();
        })
      );
  };

  public updateMessage = (data: TMessageRequest, id: string, success: () => void, failure: () => void) => {
    doUpdateMessage(data, id)
      .then(
        action('doUpdateMessageSuccess', () => {
          this.reFetchMessages(success);
        })
      )
      .catch(
        action('doUpdateMessageFailure', () => {
          failure();
        })
      );
  };

  public reorderMessage = (id: string, afterId: Nullable<string>, success: () => void, failure: () => void) => {
    this.root.appStore.lock();
    doReorderMessage(id, afterId)
      .then(
        action('doReorderMessageSuccess', () => {
          this.reFetchMessages(success);
          this.root.appStore.unlock();
        })
      )
      .catch(
        action('doReorderMessageFailure', () => {
          failure();
        })
      );
  };

  @computed
  get isLoading() {
    // todo: добавить 3 статуса загрузки - инит, лоадинг, лоадед
    if (this.root.appStore.userIsGuest) return false;
    return !this.isLoaded;
  }
}

export default ConfigStore;
export type TConfigStore = ConfigStore;
