import { EVariant } from 'stores/MessageDialogStore';

export enum EType {
  TEXT = 'TE',
  TIME = 'TI',
  DATE = 'DA',
  PAUSE = 'PA',
}

export enum ECustomOrPreset {
  CUSTOM = 'CU',
  PRESET = 'PR',
}

export type TMessageResponse = {
  id: string;
  stack_rank: number;
  custom_or_preset: ECustomOrPreset;
  type: EType;
  value_text: string;
  scroll_pace: number;
  flash_rate: number;
  font: string;
  last_modified_date: Date;
  last_modified_user: string;
};

export type TMessageRequest = {
  id?: string;
  stack_rank?: number;
  custom_or_preset: ECustomOrPreset;
  type: EType;
  value_text: string;
  scroll_pace: number;
  flash_rate: number;
  font: string;
};

export type TMessage = {
  id: string;
  stackRank: number;
  customOrPreset: ECustomOrPreset;
  type: EType;
  valueText: string;
  scrollPace: number;
  flashRate: number;
  font: string;
};

export type TMessageFormik = {
  id?: string;
  valueText: string;
  scrollPace: number;
  flashRate: number;
  font: string;
  variant: EVariant;
};
