import { action, makeObservable, observable, computed } from 'mobx';
import { fetchStatus, doTestLeds, doSetBrightness, doTurnDisplayOff, doTurnDisplayOn, doClearErrors, doRebootSign } from 'services/api/About';
import { TAboutData } from 'types/about';
import _merge from 'lodash/merge';
import { DateTime } from 'luxon';

class AboutModuleStore {
  @observable isInit = false;
  @observable data: TAboutData = {
    DisplayMessages: true,
    ServerConnectedToSign: false,
    LastSyncUTC: '',
    SignClock: '',
    LightSensor: 0,
    SignBrightness: 0,
    LedsBurnedOut: 0,
    LastErrorMsg: '',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public initiate = (force = false) => {
    if (this.isInit && !force) return;
    Promise.all([fetchStatus()]).then(
      action('initAboutResult', values => {
        const [statusResponse] = values;
        const init = {};
        this.data = _merge(
          this.data,
          statusResponse.result.reduce((p, c) => ({ ...p, ...c }), init)
        );
        this.isInit = true;
      })
    );
  };

  @action
  public changeDisplayMessages = (value: boolean, callback: () => void) => {
    this.data.DisplayMessages = value;
    if (value) {
      doTurnDisplayOn().then(action('doTurnDisplayOff', () => callback()));
    } else {
      doTurnDisplayOff().then(action('doTurnDisplayOff', () => callback()));
    }
  };

  @action
  public changeSignBrightness = (value: number) => {
    this.data.SignBrightness = value;
  };

  @action
  public saveSignBrightness = (callback: () => void) => {
    doSetBrightness(this.data.SignBrightness).then(action('doSetBrightness', () => callback()));
  };

  @action
  public testLeds = (callback: (result: string) => void) => {
    doTestLeds().then(
      action('doTestLeds', response => {
        callback(response.result);
      })
    );
  };

  @action
  public clearErrors = (callback: (result: string) => void) => {
    doClearErrors().then(
      action('doClearErrors', response => {
        callback(response.result);
        this.initiate(true);
      })
    );
  };

  @action
  public rebootSign = (callback: (result: string) => void) => {
    doRebootSign().then(
      action('doRebootSign', response => {
        callback(response.result);
        this.initiate(true);
      })
    );
  };

  @computed
  get isAutoBrightness() {
    return this.data.SignBrightness === 0;
  }

  @action
  public refresh = () => {
    this.initiate(true);
  };

  @computed
  get lastSyncFormatted() {
    return DateTime.fromISO(this.data.LastSyncUTC).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  }

  @computed
  get lastSyncReadable() {
    return DateTime.fromISO(this.data.LastSyncUTC).toRelative() || '';
  }

  @computed
  get signClockFormatted() {
    return DateTime.fromISO(this.data.SignClock).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  }
}

export default AboutModuleStore;
export type TAboutModuleStore = AboutModuleStore;
