import { action, computed, makeObservable, observable } from 'mobx';
import { EMode, EProgress } from 'types/app';
import { ECustomOrPreset, EType, TMessage, TMessageFormik, TMessageRequest } from 'types/message';
import { TRootStore } from 'stores/RootStore';
import { getDefaultInitialValues, getInitialValuesForCopy, getInitialValuesForEdit, prepareValuesForRequest } from 'utils/message.form';

export enum EVariant {
  APPEAR = 'APPEAR',
  SCROLL = 'SCROLL',
  FLASH = 'FLASH',
}

class MessageDialogStore {
  @observable root: TRootStore;
  @observable progress: EProgress = EProgress.INIT;
  @observable init = false;
  @observable mode: EMode = EMode.CREATE;
  @observable customOrPreset: ECustomOrPreset = ECustomOrPreset.CUSTOM;
  @observable data?: TMessage;

  static variantOptions = [
    { value: EVariant.APPEAR, label: 'Appear (no effect)' },
    { value: EVariant.SCROLL, label: 'Scroll' },
    { value: EVariant.FLASH, label: 'Flash / blink' },
  ];

  constructor(rootSore: TRootStore) {
    this.root = rootSore;
    makeObservable(this);
  }

  @action
  public open = (customOrPreset: ECustomOrPreset, mode: EMode, data?: TMessage) => {
    this.progress = EProgress.LOADED;
    this.customOrPreset = customOrPreset;
    this.mode = mode;
    this.init = true;
    if (mode === EMode.EDIT || mode === EMode.COPY) this.data = data;
  };

  @action
  public close = () => {
    this.progress = EProgress.INIT;
  };

  @computed
  get isOpened() {
    return this.progress !== EProgress.INIT;
  }

  @computed
  get title() {
    if (this.customOrPreset === ECustomOrPreset.PRESET) {
      return this.mode === EMode.CREATE ? 'Add Preset' : 'Edit Preset';
    }
    if (this.customOrPreset === ECustomOrPreset.CUSTOM) {
      return this.mode === EMode.CREATE || this.mode === EMode.COPY ? 'Add Message' : 'Edit Message';
    }
    return '';
  }

  @computed
  get initialValues(): TMessageFormik {
    if (this.mode === EMode.CREATE) return getDefaultInitialValues();
    if (this.mode === EMode.COPY) return getInitialValuesForCopy(this.data as TMessage);
    return getInitialValuesForEdit(this.data as TMessage);
  }

  prepareValues(values: TMessageFormik): TMessageRequest {
    return prepareValuesForRequest(EType.TEXT, this.customOrPreset, values);
  }

  @action
  public doSave = (values: TMessageFormik, success: () => void, failure: () => void) => {
    if (this.mode === EMode.CREATE || this.mode === EMode.COPY) {
      this.root.configStore.createMessage(this.prepareValues(values), success, failure);
    }
    if (this.mode === EMode.EDIT) {
      this.root.configStore.updateMessage(this.prepareValues(values), values.id as string, success, failure);
    }
  };
}

export default MessageDialogStore;
export type TMessageDialogStore = MessageDialogStore;
