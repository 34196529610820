import PrimaryClient from 'services/PrimaryClient';
import { TStatusResponse } from 'types/about';

const api = PrimaryClient.getClient();

export const fetchStatus = () => api().get<TStatusResponse>('/v1/status/');
export const doSetBrightness = (brightness: number) => api().post('/v1/sign/setBrightness/', { brightness });
export const doTestLeds = () => api().get<string>('/v1/sign/testLeds/');
export const doClearErrors = () => api().post<string>('/v1/sign/clearErrors/', {});
export const doRebootSign = () => api().post<string>('/v1/sign/rebootSign/', {});
export const doTurnDisplayOff = () => api().post<string>('/v1/sign/turnDisplayOff/', {});
export const doTurnDisplayOn = () => api().post<string>('/v1/sign/turnDisplayOn/', {});
