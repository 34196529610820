import PrimaryClient from 'services/PrimaryClient';

const api = PrimaryClient.getClient();
export const signIn = (username: string, password: string) => {
  const data = {
    username,
    password,
  };
  const client = api();
  return client.rawPost<{ access: string; refresh: string }>('/token/', data).then(client.handleSuccess).catch(client.handleError);
};
export const doRefreshToken = (refresh: string) => {
  const client = api();
  return client.rawPost<{ access: string; refresh: string }>('/token/refresh/', { refresh }).then(client.handleSuccess).catch(client.handleError);
};
export const signOut = (refresh: string) => api().post('/token/revoke/', { refresh });
