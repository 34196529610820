import PrimaryClient from 'services/PrimaryClient';
import { TMessageRequest, TMessageResponse } from 'types/message';
import { Nullable } from 'types/app';

const api = PrimaryClient.getClient();

export const fetchMessages = () => api().get<TMessageResponse[]>('/v1/messages/');
export const doCreateMessage = (data: TMessageRequest) => api().post<TMessageResponse>('/v1/messages/', data);
export const doUpdateMessage = (data: TMessageRequest, id: string) => api().put<TMessageResponse>(`/v1/messages/${id}/`, data);
export const doDeleteMessage = (id: string) => api().delete<TMessageResponse>(`/v1/messages/${id}/`);
export const doReorderMessage = (id: string, afterId: Nullable<string>) =>
  api().post<TMessageResponse>(`/v1/messages/${id}/reorder/`, { move_after_id: afterId });
