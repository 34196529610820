import { DEFAULT_FONT } from 'constants/app';
import { EVariant } from 'stores/MessageDialogStore';
import { ECustomOrPreset, EType, TMessage, TMessageFormik, TMessageRequest } from 'types/message';
import _omit from 'lodash/omit';

export const getDefaultInitialValues = (): TMessageFormik => ({
  valueText: '',
  scrollPace: 0,
  flashRate: 0,
  font: DEFAULT_FONT,
  variant: EVariant.APPEAR,
});

export const getInitialValuesForEdit = (data: TMessage): TMessageFormik => {
  const d = { ...data };

  let variant = EVariant.APPEAR;
  if (d.scrollPace > 0) variant = EVariant.SCROLL;
  if (d.flashRate > 0) variant = EVariant.FLASH;

  return {
    id: d.id,
    valueText: d.valueText,
    scrollPace: d.scrollPace,
    flashRate: d.flashRate,
    font: d.font,
    variant,
  };
};

export const getInitialValuesForCopy = (data: TMessage): TMessageFormik => _omit(getInitialValuesForEdit(data), 'id');

export const prepareValuesForRequest = (type: EType, customOrPreset: ECustomOrPreset, values: TMessageFormik): TMessageRequest => ({
  custom_or_preset: customOrPreset,
  type,
  value_text: values.valueText,
  scroll_pace: values.variant === EVariant.SCROLL ? values.scrollPace : 0,
  flash_rate: values.variant === EVariant.FLASH ? values.flashRate : 0,
  font: values.font,
});
