export enum EProgress {
  INIT = -1,
  LOADING = 0,
  LOADED = 1,
}

export enum ERef {
  GROUPS = 'groups',
}

export type Nullable<T> = T | null;

export type TId = string;

export enum EElement {
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  PROGRAM = 'PROGRAM',
  PRESET = 'PRESET',
  ABOUT = 'ABOUT',
}

export enum EMode {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  COPY = 'COPY',
}

export type TFormSelectOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
};
