import React, { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { getAboutUrn, getPresetUrn, getProgramUrn } from 'utils/getUrn';
import Container from '@mui/material/Container';
import useRootStore from 'stores/hooks/useRootStore';
import { observer } from 'mobx-react';
import { EElement } from 'types/app';
import Dialogs from 'components/Dialogs';
import { LinearProgress } from '@mui/material';

const Tabs = styled((props: TabsProps) => <MuiTabs {...props} />)({
  '& .MuiTabs-indicator': {
    bottom: 'auto',
    top: 0,
    backgroundColor: '#fff',
  },
});

const Wrapper: FC = () => {
  const navigate = useNavigate();
  const { appStore, configStore } = useRootStore();

  useEffect(() => {
    configStore.fetchConfig();
  }, [configStore]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let urn = '';
    if (newValue === EElement.PRESET) urn = getPresetUrn();
    if (newValue === EElement.PROGRAM) urn = getProgramUrn();
    if (newValue === EElement.ABOUT) urn = getAboutUrn();

    navigate(urn, { replace: true });
  };

  if (configStore.isLoading) return <LinearProgress />;

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="static">
        <Container maxWidth="sm">
          <Tabs value={appStore.elementForMenu} onChange={handleChange} centered textColor="inherit" variant="fullWidth">
            <Tab label="Program" value={EElement.PROGRAM} />
            <Tab label="Preset" value={EElement.PRESET} />
            <Tab label="About" value={EElement.ABOUT} />
          </Tabs>
        </Container>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
        pb={2}
      >
        <Outlet />
      </Box>
      <Dialogs />
    </Box>
  );
};

export default observer(Wrapper);
