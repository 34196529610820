import React, { FC, Suspense } from 'react';
import { LinearProgress } from '@mui/material';
import AccessGuestsOnly from 'router/AccessGuestsOnly';

const Module = React.lazy(() => import('modules/LoginModule'));
const LoginElement: FC = () => (
  <Suspense fallback={<LinearProgress />}>
    <AccessGuestsOnly>
      <Module />
    </AccessGuestsOnly>
  </Suspense>
);

export default LoginElement;
