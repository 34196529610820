import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useRootStore from 'stores/hooks/useRootStore';
import { observer } from 'mobx-react';
import GlobalLoader from 'components/system/GlobalLoader';

import Wrapper from 'components/Wrapper';
import Envelope from 'components/Envelope';
import HomeElement from 'router/elements/HomeElement';
import ProgramElement from 'router/elements/ProgramElement';
import NotFoundElement from 'router/elements/NotFoundElement';
import PresetElement from 'router/elements/PresetElement';
import AboutElement from 'router/elements/AboutElement';
import LoginElement from 'router/elements/LoginElement';

const AppRouter: FC = () => {
  const { appStore } = useRootStore();

  useEffect(() => {
    appStore.initiate();
  }, [appStore]);

  if (!appStore.isInit) {
    return <GlobalLoader />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Envelope />}>
          <Route index element={<HomeElement />} />
          <Route element={<Wrapper />}>
            <Route path="program/*" element={<ProgramElement />} />
            <Route path="preset/*" element={<PresetElement />} />
            <Route path="about/*" element={<AboutElement />} />
          </Route>

          <Route path="login" element={<LoginElement />} />
          <Route path="*" element={<NotFoundElement />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default observer(AppRouter);
