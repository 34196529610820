import React, { FC, Suspense } from 'react';
import { observer } from 'mobx-react';
import Confirm from 'components/Dialogs/Confirm';
import dialogStore, { EDialog } from 'stores/DialogStore';
import useRootStore from 'stores/hooks/useRootStore';
import GlobalLoader from 'components/system/GlobalLoader';

const DateDialog = React.lazy(() => import('components/Dialogs/DateDialog'));
const MessageDialog = React.lazy(() => import('components/Dialogs/MessageDialog'));
const TimeDialog = React.lazy(() => import('components/Dialogs/TimeDialog'));
const PauseDialog = React.lazy(() => import('components/Dialogs/PauseDialog'));
const FromPresetDialog = React.lazy(() => import('components/Dialogs/FromPresetDialog'));
const Dialogs: FC = () => {
  const { messageDialogStore, appStore } = useRootStore();

  return (
    <>
      {appStore.isLock && <GlobalLoader invisible={false} />}
      <Confirm />
      {messageDialogStore.init && (
        <Suspense fallback={<></>}>
          <MessageDialog />
        </Suspense>
      )}
      {dialogStore.stack.includes(EDialog.MESSAGE_TIME) && (
        <Suspense fallback={<></>}>
          <TimeDialog />
        </Suspense>
      )}
      {dialogStore.stack.includes(EDialog.MESSAGE_DATE) && (
        <Suspense fallback={<></>}>
          <DateDialog />
        </Suspense>
      )}
      {dialogStore.stack.includes(EDialog.MESSAGE_PAUSE) && (
        <Suspense fallback={<></>}>
          <PauseDialog />
        </Suspense>
      )}
      {dialogStore.stack.includes(EDialog.MESSAGE_PRESET) && (
        <Suspense fallback={<></>}>
          <FromPresetDialog />
        </Suspense>
      )}
    </>
  );
};

export default observer(Dialogs);
