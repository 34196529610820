import { action, computed, makeObservable, observable } from 'mobx';
import { EMode } from 'types/app';
import { TRootStore } from 'stores/RootStore';
import _sortBy from 'lodash/sortBy';
import { ECustomOrPreset, TMessage } from 'types/message';
import update from 'immutability-helper';

class PresetModuleStore {
  @observable root: TRootStore;
  @observable isInit = false;
  @observable mode: EMode = EMode.VIEW;
  @observable searchText = '';
  @observable orderedMessages: TMessage[] = [];

  constructor(rootSore: TRootStore) {
    this.root = rootSore;
    makeObservable(this);
  }

  @action
  public initiate = () => {
    this.isInit = true;
  };

  @action
  public setSearchText = (value: string) => {
    this.searchText = value;
  };

  @action
  public changeMode = () => {
    this.mode = this.mode === EMode.VIEW ? EMode.EDIT : EMode.VIEW;
    if (this.isEditMode) this.loadOrderedMessages();
  };

  @action
  public loadOrderedMessages = () => {
    this.orderedMessages = update(this.data, {});
  };

  @action
  public setOrderedMessage = (dragIndex: number, hoverIndex: number) => {
    this.orderedMessages = update(this.orderedMessages, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, this.orderedMessages[dragIndex]],
      ],
    });
  };

  @computed
  get currentSearchText() {
    return this.mode === EMode.EDIT ? '' : this.searchText;
  }

  @computed
  get isEditMode() {
    return this.mode === EMode.EDIT;
  }

  @computed
  get list() {
    if (this.isEditMode) return this.orderedMessages;
    if (this.searchText.length === 0) return this.data;
    return this.data.filter(i => i.valueText.indexOf(this.searchText) !== -1);
  }

  @computed
  get data() {
    return _sortBy(
      this.root.configStore.messages.filter(i => i.customOrPreset === ECustomOrPreset.PRESET),
      'stackRank'
    );
  }

  @computed
  get options() {
    return this.data.map(i => ({ value: i.id, label: i.valueText }));
  }
}

export default PresetModuleStore;
export type TPresetModuleStore = PresetModuleStore;
